<template>
  <v-row>
    <v-col cols="12" md="3">
      <AdminMenu></AdminMenu>
    </v-col>
    <v-col cols="12" md="9" class="adminBg">
      <AdminStatistics></AdminStatistics>
      <v-layout wrap>
        <v-col cols="12">
          <v-layout wrap class="whiteBg">
            <v-col cols="12">
              <v-layout wrap>
                <h5>Recent Products</h5>
              </v-layout>
              <v-layout wrap>
                <v-col
                  cols="6" md="3"
                  v-for="item in recentSellingProducts.slice(0,8)"
                  :key="item.productId"
                >
                  <v-img height="80" :src="item.productImagePath" contain />
                  <p style="font-size:11px;" align="left">
                    {{ item.name }}<br />
                    <b>{{ systemSetting.systemCurrency }} {{ item.price }}</b><br />
                    Product Owner: {{ item.User.Role.name }}<br />
                  </p>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
        </v-col>
      </v-layout>
    </v-col>
  </v-row>
</template>
<script>
import AdminMenu from "../others/AdminMenu";
import AdminStatistics from "../others/AdminStatistics";
export default {
  name: "AdminDashboard",
  components: {
    AdminMenu,
    AdminStatistics,
  },
  data() {
    return {
      systemSetting: {
        systemCurrency: "GH₵",
      },
      saleOrders: [
        {
          orderId: "SU0046",
          customer: "Emmanuel Kalu",
          fullfilmentStatus: "Fullfiled",
          paymentStatus: "Paid",
          Total: 3600,
        },
        {
          orderId: "SU0045",
          customer: "Chime Emmanuel",
          fullfilmentStatus: "Cancelled",
          paymentStatus: "Partially Paid",
          Total: 8400,
        },
        {
          orderId: "SU0044",
          customer: "John Doe",
          fullfilmentStatus: "Draft",
          paymentStatus: "Partially Paid",
          Total: 9400,
        },
      ],
      recentSellingProducts:[],
    };
  },
  created() {
    this.getProducts();
  },
  methods: {
    getProducts() {
      this.$http
        .get(`${process.env.VUE_APP_URL}products`)
        .then((response) => {
          this.recentSellingProducts = response.data.data.reverse();
        });
    },
  }
};
</script>
<style scoped>
.adminBg {
  background: #e5e5e5;
}

.whiteBg {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
}
</style>
