<template>
  <v-row>
    <v-col cols="12" class="adminBg">
      <v-layout wrap>
        <v-col cols="12" md="3">
          <v-layout class="box1">
            <v-col cols="12">
              <p class="text-h6 text-white">Manufacturers</p>
              <p class="text-h6 text-white">
                <v-icon color="#ffffff">mdi-account-multiple-outline</v-icon>
                <span class="ml-3 text-h5"><b>{{manufacturers.length}}</b></span>
              </p>
            </v-col>
          </v-layout>
        </v-col>

        <v-col cols="12" md="3">
          <v-layout class="box2">
            <v-col cols="12">
              <p class="text-h6 text-white">Distributors</p>
              <p class="text-h6 text-white">
                <v-icon color="#ffffff">mdi-account-multiple-outline</v-icon>
                <span class="ml-3 text-h5"><b>{{distributors.length}}</b></span>
              </p>
            </v-col>
          </v-layout>
        </v-col>

        <v-col cols="12" md="3">
          <v-layout class="box3">
            <v-col cols="12">
              <p class="text-h6 text-white">Retailers</p>
              <p class="text-h6 text-white">
                <v-icon color="#ffffff">mdi-account-multiple-outline</v-icon>
                <span class="ml-3 text-h5"><b>{{retailers.length}}</b></span>
              </p>
            </v-col>
          </v-layout>
        </v-col>

        <v-col cols="12" md="3">
          <v-layout class="box2">
            <v-col cols="12">
              <p class="text-h6 text-white">Logistics</p>
              <p class="text-h6 text-white">
                <v-icon color="#ffffff">mdi-truck</v-icon>
                <span class="ml-3 text-h5"><b>{{logistics.length}}</b></span>
              </p>
            </v-col>
          </v-layout>
        </v-col>
      </v-layout>

      <v-layout wrap class="mt-5">
        <v-col cols="12" md="4">
          <v-layout class="boxStat">
            <v-col cols="12">
              <v-layout>
                <v-col cols="12">
                  <v-icon color="#2776ED" size="30"
                    >mdi-shopping-outline</v-icon
                  >
                  <span class="ml-5">All Users (Buyers)</span>
                </v-col>
              </v-layout>
              <v-layout>
                <v-col cols="9">
                  <span class="text-h5 ml-5"><b>{{users.length}}</b></span>
                </v-col>
                <v-col cols="3">
                  <v-badge
                    bordered
                    color="error"
                    :content="unApprovedUsers.length"
                    overlap
                  >
                    <v-btn small to="/admin/users-account/module">
                      <v-icon size="20">mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-badge>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
        </v-col>

        <v-col cols="12" md="4">
          <v-layout class="boxStat">
            <v-col cols="12">
              <v-layout>
                <v-col cols="12">
                  <v-icon color="#D89515" size="30"
                    >mdi-timer-sand-complete</v-icon
                  >
                  <span class="ml-5">All Products</span>
                </v-col>
              </v-layout>
              <v-layout>
                <v-col cols="9">
                  <span class="text-h5 ml-5"><b>{{products.length}}</b></span>
                </v-col>
                <v-col cols="3">
                    <v-btn small>
                      <v-icon size="20">mdi-chevron-right</v-icon>
                    </v-btn>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
        </v-col>

        <v-col cols="12" md="4">
          <v-layout class="boxStat">
            <v-col cols="12">
              <v-layout>
                <v-col cols="12">
                  <v-icon color="#BD2121" size="30"
                    >mdi-delete-forever-outline</v-icon
                  >
                  <span class="ml-5">Product Categories</span>
                </v-col>
              </v-layout>
              <v-layout>
                <v-col cols="9">
                  <span class="text-h5 ml-5"><b>{{productCategories.length}}</b></span>
                </v-col>
                <v-col cols="3">
                  <v-btn small>
                    <v-icon size="20">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
        </v-col>
      </v-layout>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "AdminStatistics",
  data() {
    return {
        manufacturers:[],
        distributors:[],
        retailers:[],
        users:[],
        unApprovedUsers:[],
        professionals:[],
        logistics:[],
        products:[],
        productCategories:[]
    };
  },
  created() {
    this.getUsers();
    this.getLogisticsCompanies();
    this.getProducts();
    this.getProductCategories();
  },
  methods: {
    getUsers() {
      this.$http
        .get(`${process.env.VUE_APP_URL}users`)
        .then((response) => {
          console.log(response.data.data)
          response.data.data.forEach(element => {
              if(element.isConfirmed == false) {
                this.unApprovedUsers.push(element);
              }
              if(element.Role.name == 'Manufacturer'){
                  this.manufacturers.push(element);
              }
              if(element.Role.name == 'Distributor'){
                  this.distributors.push(element);
              }
              if(element.Role.name == 'Retailer'){
                  this.retailers.push(element);
              }
              if(element.Role.name == 'User'){
                  this.users.push(element);
              }
              if(element.Role.name == 'Professional'){
                  this.professionals.push(element);
              }
              
          });
        });
    },

    getLogisticsCompanies() {
      this.$http
        .get(`${process.env.VUE_APP_URL}logistics`)
        .then((response) => {
          this.logistics = response.data.data;
        });
    },

    getProducts() {
      this.$http
        .get(`${process.env.VUE_APP_URL}products`)
        .then((response) => {
          this.products = response.data.data;
        });
    },

    getProductCategories() {
      this.$http
        .get(`${process.env.VUE_APP_URL}product-categories`)
        .then((response) => {
          this.productCategories = response.data.data;
        });
    },
  }
};
</script>
<style scoped>
.adminBg {
  background: #e5e5e5;
}

.boxStat {
  background: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
}

.box1 {
  background: linear-gradient(
    109.34deg,
    #d89515 12.27%,
    #d68f15 15.18%,
    #bd2121 62.99%
  );
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
}

.box2 {
  background: linear-gradient(
    109.34deg,
    #000000 12.28%,
    rgba(39, 118, 237, 0.96) 62.99%
  );
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
}

.box3 {
  background: linear-gradient(109.34deg, #72bd61 15.18%, #111212 62.99%);
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
}
</style>
